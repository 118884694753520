
import { Vue, Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";
import { RelatorioService } from "@/core/services/financeiro";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { GenerateYearOptions } from "@/assets/scripts/helper";
import { TratarErroApi } from '@/assets/scripts/helper';

@Component
export default class ListaBanco extends mixins(Vue, ListPage) {
  filter: any = new Model();
  relatorio:any = {};
  tab = null;

  empreendimentosIds = [];
  empresasIds = [];
  anos = GenerateYearOptions();

  loading: boolean = false;
  dialogCadastro: boolean = false;

  isLoading: boolean = true;

  titulo: string = "Painel VGV";
  subTitulo: string = "tipos de bancos cadastrados no Sistema";

  options: any = {
    itemsPerPage: 15,
  };

 get selecionarTodosEmpreendimentos(){
    return this.filter.empreendimentosIds.length === this.empreendimentosIds.length
  }

  get SelecionarAlgunsEmpreendimentos(){
    return this.filter.empreendimentosIds.length > 0 && !this.selecionarTodosEmpreendimentos
  }
 
  get iconEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpreendimento(){
    if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
    return 'Selecionar todos'
  }

  SelecionarEmpreendimento() {
    this.$nextTick(() => {
      if (this.selecionarTodosEmpreendimentos) {
        this.filter.empreendimentosIds = []
      } else {
        this.filter.empreendimentosIds = this.empreendimentosIds.slice().map((x:any)=>x.id)
      }
      this.ObterRelatorio();
    })
  } 

  SelecionarEmpresa(){
    this.$nextTick(() => {
      if (this.selecionarTodosEmpresas) {
        this.filter.empresasIds = []
      } else {
        this.filter.empresasIds = this.empresasIds.slice().map((x:any)=>x.id)
      }
      this.ObterRelatorio();
    })
  }
  
  get iconEmpresa(){
    if (this.selecionarTodosEmpresas) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpresa(){
    if (this.selecionarTodosEmpresas) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
    return 'Selecionar todos'
  } 

  get selecionarTodosEmpresas(){
    return this.filter.empresasIds.length === this.empresasIds.length
  }

  get SelecionarAlgunsEmpresas(){
    return this.filter.empresasIds.length > 0 && !this.selecionarTodosEmpresas
  }


  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Nome", value: "nome" },
  ];

  breadCrumbLinks: any[] = [
    { text: 'Home',
      href: '/financeiro/painel/kpi',
    },
    {
    text: 'Painel VGV',
    disabled: true,
    href: '/financeiro/painel/vgv'
    }]

  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } =
      this.options;
      this.loading = true;

      this.service.Listar(page,itemsPerPage,sortBy,sortDesc,search,columns,undefined,"","id, nome","").then(
        (res) => {
          this.lista = res.data.items;
          this.totalLista = res.data.count;
        },
      ).finally(() => (this.loading = false));
    }

 
  
  
  ObterRelatorio(){
    this.isLoading = true
    let empreendimentosIds = this.filter.empreendimentosIds ? this.filter.empreendimentosIds.toString() : '';
    let empresasIds =  this.filter.empresasIds ? this.filter.empresasIds.toString() : '';

    if(this.filter.ano == "Todos os anos"){
      this.filter.ano = 0;
    }
 
    new RelatorioService().GetRelatorioVgv(empresasIds, empreendimentosIds, this.filter.taxa, this.filter.ano, this.filter.correcaoCarteira, this.filter.reajusteEstoque, this.filter.diasInadimplente).then(
      (res) => {
            if (!res || !res.data) {
      this.isLoading = false;
      return; // Sai da função
    }
        this.relatorio = res.data;
         this.isLoading = false
      },
       (err) => {
        this.isLoading = false;
        TratarErroApi(err)
      }
    )    
  }
   mounted() {
    this.ObterRelatorio();

    new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
      res => this.empreendimentosIds = res.data.items
    );

    new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome, nomeFantasia', '').then(
      res => this.empresasIds = res.data.items
    );
  }   
}

class Model {
  empresasIds = [];
  empreendimentosIds = [];
  unidadesIds = [];
  taxa = "";
  ano = new Date().getFullYear();
  correcaoCarteira = "";
  reajusteEstoque = "";
  diasInadimplente = "";
}
